import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { Input, Button, Checkbox } from '../../components/base';
import { validateRequiredFields } from '../../utils/validation.util';
import demoLogo from '../../assets/img/logo.png';

export default class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        value: {},
        error: {},
      },
    };
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleFormChange(event) {
    const {
      name,
      value,
      type,
      checked = false,
    } = event.target;
    this.setState((prevState) => {
      let formattedValue = value;
      if (type === 'checkbox') {
        formattedValue = checked;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            [name]: formattedValue,
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
        },
      };
    });
  }

  async handleLogin(e) {
    e.preventDefault();
    const { form = {} } = this.state;
    const { handleLogin, history } = this.props;
    const error = validateRequiredFields(form.value, ['email', 'password']);
    this.setState(prevState => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      await handleLogin(form.value, history.push);
    }
  }

  render() {
    const { form = {} } = this.state;
    return (
      <div className="login">
        <div className="login__simpeel-logo">
          {/* <img src={simpeelLogo} alt="Simpeel" /> */}
        </div>
        <form
          className="login__form"
          onSubmit={this.handleLogin}
        >
          <img src={demoLogo} style={{width: '150px', height: '1445x'}} alt="demo" />
          <h3 className="login__form-title">Masuk Member Area</h3>
          {/* <h3 className="login__form-title">Masuk Member Area</h3> */}
          <div className="login__input-wrapper">
            <span className="icon-user login__input-wrapper--icon" />
            <Input
              noMargin
              name="email"
              placeholder="ad"
              onChange={this.handleFormChange}
              value={form.value.email || ''}
              error={form.error.email || ''}
            />
          </div>
          <div className="login__input-wrapper">
            <span className="icon-lock login__input-wrapper--icon" />
            <Input
              noMargin
              name="password"
              type="password"
              onChange={this.handleFormChange}
              value={form.value.password || ''}
              error={form.error.password || ''}
            />
          </div>
          <div className="login__input-wrapper">
            <Checkbox
              noMargin
              center
              name="remember_me"
              label="Remember Me"
              onChange={this.handleFormChange}
              checked={form.value.remember_me || false}
            />
          </div>
          <Button
            type="submit"
            title="Login"
          />
        </form>
      </div>
    );
  }
}
Login.propTypes = {
  handleLogin: PropTypes.func,
  history: PropTypes.object.isRequired,
};
Login.defaultProps = {
  handleLogin: noop,
};
