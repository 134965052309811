import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import logo from '../../assets/img/logo.png';
import { Button, Input } from '../../components/base';
import { validateRequiredFields } from '../../utils/validation.util';

export default class Admission extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        value: {},
        error: {},
      },
    };
    this._onClickNext = this._onClickNext.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }

  _onFormChange(event) {
    const {
      name,
      value,
    } = event.target;
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value,
        },
        error: {
          ...prevState.form.error,
          [name]: '',
        },
      },
    }));
  }

  async _onClickNext(e) {
    e.preventDefault();
    const { form = {} } = this.state;
    const { history } = this.props;
    const error = validateRequiredFields(form.value, ['name']);
    this.setState(prevState => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      history.push('/admission/buat-pendaftaran', { name: form.value.name });
    }
  }

  render() {
    const { form } = this.state;
    return (
      <div className="admission">
        <img className="admission__logo" src={logo} alt="" />
        <form
          className="admission__info"
          onSubmit={this._onClickNext}
        >
          <div className="admission__info-list">
            <div>Selamat Datang</div>
          </div>
          <div className="admission__info-list">
            <div>Silakan Masukkan Nama</div>
            <div>
              <Input
                noMargin
                name="name"
                onChange={this._onFormChange}
                value={form.value.name}
                error={form.error.name}
              />
            </div>
          </div>
          <div className="admission__submit">
            <Button
              type="submit"
              title="Lanjut"
            />
          </div>
        </form>
      </div>
    );
  }
}
Admission.propTypes = {
  history: PropTypes.object.isRequired,
};
Admission.defaultProps = {};
