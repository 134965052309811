import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { SearchSelect } from '../base';

class HeaderComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.onClickUnit = this.onClickUnit.bind(this);
  }

  onClickUnit(event) {
    const { setWorkingUnit } = this.props;
    const { target } = event;

    // console.log(event)
    target.value.id = target.value.value;
    target.value.title = target.value.label;
    setWorkingUnit(target.value);
  }

  render() {
    const {
      user, onSearchUnit,
    } = this.props;
    const { school_unit, workingUnit } = user;

    let defaultValue = {
      id: '0',
      title: 'SEMUA UNIT',
      value: '0',
      label: 'SEMUA UNIT',
    };

    if (workingUnit && workingUnit.attributes) {
      defaultValue = {
        id: workingUnit.attributes.id,
        title: workingUnit.attributes.name,
      };
    } else if (school_unit && school_unit.unit_code) {
      if (school_unit.id !== 9) {
        defaultValue = {
          value: school_unit.id,
          label: school_unit.name,
        };
      }
    }

    return (
      <div>
        <div className="unit_selector">
          <SearchSelect
            async
            onSearch={onSearchUnit}
            onClick={this.onClickUnit}
            labelName="label"
            valueName="value"
            placeholder="Pilih Unit Kerja"
            value={user.workingUnit || defaultValue}
          />
        </div>
      </div>
    //   </div>
    // </header>
    );
  }
}

export default function Header2(props) {
  return (
    <HeaderComponent {...props} />
  );
}

HeaderComponent.propTypes = {
  onSearchUnit: PropTypes.func,
  setWorkingUnit: PropTypes.func,
  user: PropTypes.object,
};

HeaderComponent.defaultProps = {
  onSearchUnit: noop,
  setWorkingUnit: noop,
  user: null,
};
