import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import logo from '../../assets/img/logo.png';
import { HOME_INFO } from '../../variables/home.variable';

class Home extends PureComponent {
  render() {
    const { user } = this.props;
    const { school_unit } = user;
    return (
      <div className="home">
        <img className="home__logo" src={logo} alt="" />
        <div className="home__info">
          {
            map(HOME_INFO, (list, index) => (
              <div key={`home__info__${index}`} className="home__info-list">
                <p>{list.label}</p>
                {
                  ((school_unit !== null)) && (
                    <p>: {school_unit[list.value]}</p>
                  )
                }
                {
                  ((school_unit === null)) && (
                    <p>PUSAT</p>
                  )
                }
              </div>
            ))
          }
        </div>
        <div />
      </div>
    );
  }
}

Home.propTypes = {
  user: PropTypes.object.isRequired,
};

export default function App(props) {
  return (
    <Home {...props} />
  );
}
