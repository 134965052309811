import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  isEmpty, map, isEqual,
} from 'lodash';
import ReactToPrint from 'react-to-print';
import {
  normalizeAmount, dateFormat,
} from '../../../utils/transformer.util';
import { Button } from '../../../components/base/index';
import logo from '../../../assets/img/logo.png';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class CetakRaportEng extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._setForm = this._setForm.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    const { user } = this.props;
    const { organizations_id } = user;

    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          organizations_id,
          listraport: [
            {
              score: '',
              subject_id: '',
              descriptions: '',
            },
          ],
        },
        error: {
          listraport: '',
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || '';
    const paramTypes = ['classrooms', 'levels'];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEdit && id) {
      this._setForm(id, data);
    }

    this._getPeriodsOptions();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async onSubmit() {
    const { history, handleSaveRaportSiswa } = this.props;
    const { form } = this.state;
    const { value } = form;
    await handleSaveRaportSiswa(value);
    history.push('/dashboard/kurikulum/raport/daftar-nilai-raport');
    history.go(0);
  }

  async onEdit() {
    const { history, handleEditLembarObsHarian } = this.props;
    const { form } = this.state;
    const { value } = form;
    await handleEditLembarObsHarian(value);
    history.push('/dashboard/kurikulum/ulangan/pengisian-lembar-observasi-harian');
    // }
  }

  async _onSearchContent(params = {}, id, data) {
    this.setState({
      content: initialContent,
    },
    async () => {
      try {
        const { handleGetRaportSiswa, user } = this.props;
        const { organizations_id, school_unit } = user;
        const { units_id } = school_unit;
        if (id) {
          const payload = await handleGetRaportSiswa({
            id,
            organizations_id,
            units_id,
          });
          this.setState({
            form: {
              value: {
                id: payload.id,
                students_id: payload.students_id,
                classrooms_id: data.classrooms_id,
                semester: payload.semester,
                organizations_id,
                name_period: payload.name_period,
                homeroom_teacher: payload.homeroom_teacher,
                subtitute_home_teacher_name:
                    payload.subtitute_home_teacher_name,
                location: payload.location,
                period: payload.period,
                average_value: payload.average_value,
                listraport: map(payload.listraport, detail => ({
                  subject_id: detail.subject_id,
                  name: detail.name,
                  kkm: detail.kkm,
                  score: detail.score,
                  descriptions: detail.descriptions,
                })),
                teacher_english: payload.teacher_english,
              },
              error: {
                listraport: [],
              },
            },
          });
        }
      } catch (err) {
        this.setState(prevState => ({
          ...prevState,
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              listraport: [],
            },
          },
        }));
      }
    });
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }),
    () => this._onSearchContent());
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id } = user;
    if (type === 'classrooms' || type === 'classrooms') {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getPeriodsOptions() {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions();
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _setForm(id, data = {}) {
    const { handleGetDaftarNilaiEng, user } = this.props;
    const { organizations_id } = user;
    try {
      const payload = await handleGetDaftarNilaiEng({ id });
      this.setState({
        form: {
          value: {
            id: payload.id,
            students_id: payload.students_id,
            assessment_date: payload.assessment_date,
            academics_type: payload.academics_type,
            semester: payload.semester,
            content_type: payload.content_type,
            expectation: payload.expectation,
            expectation_kognitif: payload.expectation_kognitif,
            teacher_notes: payload.teacher_notes,
            organizations_id,
            period: payload.period,
            name_period: payload.name_period,
            homeroom_teacher: payload.homeroom_teacher,
            subtitute_home_teacher_name: payload.subtitute_home_teacher_name,
            location: payload.location,

            obs_result_vocab: payload.obs_result_vocab,
            obs_result_speaking: payload.obs_result_speaking,
            obs_result_listening: payload.obs_result_listening,
            obs_result_singing: payload.obs_result_singing,
            teacher_id: payload.teacher_id,
            teacher_english: payload.teacher_english,
          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  _onFormChange(event) {
    const { user } = this.props;
    const { organizations_id } = user;
    const {
      name, value, dataset, checked, type,
    } = event.target;
    const {
      inputType = 'text',
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked ? value : '';
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName] || [];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: '' }),
          },
        },
      };
    },
    () => {
      if (name === 'levels_id') {
        this._getParamOptionsAcademics('classrooms', {
          levels_id: value,
          organizations_id,
        });
      }
    });
  }

  _onClickNext() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/daftar-siswa-kelas-unit');
  }

  render() {
    const { location, user } = this.props;
    const { form } = this.state;
    const { state = {} } = location;
    const { data } = state;
    const { school_unit } = user;
    const componentRef = React.createRef();
    let sem;
    if (form.value.semester === 'ganjil') {
      sem = 'I';
    } else if (form.value.semester === 'genap') {
      sem = 'II';
    }

    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>English Progress Report</h1>
          <hr />
        </div>
        <ReactToPrint
          trigger={() => (
            <div className="form-group-member">
              <Button title="Cetak" />
            </div>
          )}
          content={() => componentRef.current}
        />
        <form style={{ padding: '20px' }} onSubmit={this._onSubmit} ref={componentRef}>
          <div className="manage-registration">
            <table className="table-report-header">
              <tr>
                <th>
                  <img className="home__logo3" src={logo} alt="home-logo" />
                </th>
                <th style={{ paddingRight: '10rem' }}>
                  <h1>English Progress Report</h1>
                </th>
              </tr>
            </table>
          </div>
          <div className="laporan-ki4__header-title3">
            <div className="laporan-ki4__header-title3--left">
              <table className="laporan-ki4__table-test">
                <tr>
                  <td />
                  <td>Name </td>
                  <td> : {data ? data.name : ''}</td>
                </tr>
                <tr>
                  <td />
                  <td>Number</td>
                  <td> : {data ? data.nis : ''}</td>
                </tr>
                <tr>
                  <td />
                  <td>Class</td>
                  <td> : {data ? data.classrooms_name : ''}</td>
                </tr>
                <tr>
                  <td />
                  <td>Semester/Date</td>
                  <td> : {sem} / {dateFormat(form.value.assessment_date)}</td>
                </tr>
              </table>
            </div>
          </div>
          <div className="manage-registration">
            <div className="fund-request__content">
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      <p><b>Vocabulary Aspect (Perbendaharaan Kata)</b></p>
                      <p style={{ padding: '10px' }}>{form.value.obs_result_vocab}</p>
                      <br />
                      <p><b>Speaking Aspect (Berbicara)</b></p>
                      <p style={{ padding: '10px' }}>{form.value.obs_result_speaking}</p>
                      <br />
                      <p><b>Listening Aspect (Mendengarkan)</b></p>
                      <p style={{ padding: '10px' }}>{form.value.obs_result_listening}</p>
                      <br />
                      <p><b>Singing Aspect (Bernyanyi)</b></p>
                      <p style={{ padding: '10px' }}>{form.value.obs_result_singing}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="manage-registration">
            <div className="fund-request__content">
              <table className="table-signature">
                <tr>
                  <th>Parent's Signature</th>
                  <th>Teacher's Signature</th>
                  <th>Headmaster's Signature</th>
                </tr>
                <tr style={{ height: '10rem' }}>
                  <th />
                  <th />
                  <th />
                </tr>
                <tr>
                  <th>&emsp;</th>
                  <th>{form.value.teacher_english}</th>
                  <th>{school_unit.headmaster}</th>
                </tr>
              </table>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
CetakRaportEng.propTypes = {
  location: PropTypes.string,
  school_unit: PropTypes.object,
  user: PropTypes.object,
  history: PropTypes.object,
  getPeriodsOptions: PropTypes.func,
  getParamOptionsAcademics: PropTypes.func,
  handleEditLembarObsHarian: PropTypes.func,
  handleSaveRaportSiswa: PropTypes.func,
  handleGetRaportSiswa: PropTypes.func,
  handleGetDaftarNilaiEng: PropTypes.func,
};
CetakRaportEng.defaultProps = {
  location: '',
  school_unit: {},
  user: {},
  history: {},
  getPeriodsOptions: () => {},
  getParamOptionsAcademics: () => {},
  handleEditLembarObsHarian: () => {},
  handleSaveRaportSiswa: () => {},
  handleGetRaportSiswa: () => {},
  handleGetDaftarNilaiEng: () => {},
};
