import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  get,
  isEmpty, map, noop,
} from 'lodash';
import ReactToPrint from 'react-to-print';
import { normalizeAmount } from '../../../utils/transformer.util';
import { Button } from '../../../components/base/index';
import logo from '../../../assets/img/logo.png';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class CatatanAnekdotView extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this._onClickBack = this._onClickBack.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._setForm = this._setForm.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._onClickAddSubTema = this._onClickAddSubTema.bind(this);
    this._onDeleteListSubTema = this._onDeleteListSubTema.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._getPrmSubTema = this._getPrmSubTema.bind(this);
    this._getKDMaterial = this._getKDMaterial.bind(this);
    this._getPrmTema = this._getPrmTema.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      page: 1,
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          levels_id,
          organizations_id,
          sub_tema: [
            {
              sub_tema_id: '',
              date: '',
              indikator: [
                {
                  kd: '',
                  name: '',
                  note: '',
                },
              ],
            },
          ],
        },
        error: {
          // indikator: "",
          // sub_tema: "",
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id, tema_id } = state.data || '';
    if (isEdit && id) {
      this._setForm(id, data);
    }
    // else

    // this._getPrmSubTema();
    this._getPrmSubTema({ filters: { tema_id } });
    this._getKDMaterial();
    this._getPeriodsOptions();
    this._getPrmTema();
  }

  async onEdit() {
    const { history, handleEditLembarObsHarian } = this.props;
    const { form } = this.state;
    const { value } = form;

    await handleEditLembarObsHarian(value);
    history.push('/dashboard/kurikulum/ulangan/pengisian-lembar-daftar-nilai/report');
    // }
  }

  async onSubmit() {
    const { history, handleSaveCatatanAnekdot } = this.props;
    const { form } = this.state;
    const { value } = form;

    await handleSaveCatatanAnekdot(value);
    history.push('/dashboard/kurikulum/ulangan/catatan-anekdot/pengisian-catatan-anekdot');
    // history.go(0);
  }

  _onClickAddSubTema() {
    const { form } = this.state;
    const { sub_tema } = form.value;

    sub_tema.push({
      sub_tema_id: '',
      date: '',
      indikator: [],
    });

    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          sub_tema,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  _onDeleteListSubTema(idx) {
    this.setState((prevState) => {
      const { sub_tema } = prevState.form.value;
      const list = JSON.parse(JSON.stringify(sub_tema));
      list.splice(idx, 1);
      return {
        ...prevState,
        sub_tema: list,
      };
    });
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { sub_tema } = form.value;
    const [{ indikator }] = sub_tema;

    sub_tema[data].indikator.push({
      kd: '',
      name: '',
      note: '',
    });

    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          ...prevState.sub_tema,
          indikator,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  _onDeleteList(data) {
    this.setState((prevState) => {
      const { idx, idxsub } = data;
      const list = prevState.form.value.sub_tema[idxsub].indikator;
      list.splice(idx, 1);
      return {
        ...prevState,
        indikator: list,
      };
    });
  }

  async _getPrmTema(filters = {}) {
    const { getPrmTema, user } = this.props;
    const { organizations_id } = user;
    const res = await getPrmTema(filters);
    filters.organizations_id = organizations_id;

    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_tema: res,
      },
    }));
  }

  async _getPrmSubTema(filters = {}) {
    const { getPrmSubTema, user } = this.props;
    const { organizations_id } = user;
    const res = await getPrmSubTema(filters);
    filters.organizations_id = organizations_id;

    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_sub_tema: res,
      },
    }));
  }

  async _getKDMaterial(filters = {}) {
    const { getKDMaterial } = this.props;
    const res = await getKDMaterial(filters);

    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        kd_prm: res,
      },
    }));
  }

  async _getPeriodsOptions() {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions();
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _getPrmAspectOptions() {
    const { getPrmAspectOptions } = this.props;
    const res = await getPrmAspectOptions();
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_aspect: res,
      },
    }));
  }

  async _setForm(id, data = {}) {
    const { handleGetCatatanAnekdot, user } = this.props;
    const { organizations_id } = user;
    const { tema_id } = data;
    try {
      const payload = await handleGetCatatanAnekdot({ id, tema_id });
      this.setState({
        form: {
          value: {
            id: payload.id,
            students_id: data.id,
            semester: data.semester,
            organizations_id,
            period_id: payload.period_id,
            tema_id: data.tema_id,
            sub_tema: map(payload.sub_tema, detail => ({
              sub_tema_id: detail.sub_tema_id,
              date: detail.date,
              indikator: map(payload.indikator, detail_indikator => ({
                kd: detail_indikator.kd,
                name: detail_indikator.name,
                note: detail_indikator.note,
              })),
            })),
          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  _onFormChange(event) {
    const {
      name, value, dataset, checked, type,
    } = event.target;
    const {
      inputType = 'text',
      inputArray = false,
      arrayPosition = 0,
      fieldName,
      arraySub,
    } = dataset;

    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        if (fieldName === 'indikator') {
          newList = prevState.form.value.sub_tema[arraySub][fieldName];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
        } else {
          newList = prevState.form.value[fieldName] || [];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
        }
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: '' }),
          },
        },
      };
    });
  }

  _onClickNext() {
    window.scrollTo(0, 0);
    this.setState(prevState => ({
      page: prevState.page + 1,
    }));
  }

  _onClickBack() {
    window.scrollTo(0, 0);
    this.setState(prevState => ({
      page: prevState.page - 1,
    }));
  }

  render() {
    const {
      location, user,
    } = this.props;
    const { state = {} } = location;
    const { data } = state;
    const { form } = this.state;
    const { sub_tema } = form.value;
    const { school_unit } = user;
    const componentRef = React.createRef();

    return (

      <div className="student-list">
        <div className="budget__title">
          <h1>Catatan Anekdot </h1>
          <hr />
        </div>
        <ReactToPrint
          trigger={() => (
            <div className="form-group-member">
              <Button title="Cetak" />
            </div>
          )}
          content={() => componentRef.current}
        />
        <form style={{ padding: '20px' }} onSubmit={this._onSubmit} ref={componentRef}>
          <div className="manage-registration">
            <table className="table-report-header">
              <tr>
                <th rowSpan="2">
                  <img className="home__logo3" alt="home-logo" src={logo} />
                </th>
                <th style={{ paddingRight: '10rem' }}>
                  <h3>CATATAN ANEKDOT</h3>
                </th>
              </tr>
              <tr>
                <th style={{ paddingRight: '10rem' }}>
                  <h3>{school_unit.name}</h3>
                </th>
              </tr>
            </table>
          </div>
          <div className="laporan-ki4__header-title3">
            <div className="laporan-ki4__header-title3--left">
              <table className="laporan-ki4__table-test">
                <tr>
                  <td />
                  <td>Nama </td>
                  <td> : {data ? data.name : ''}</td>
                </tr>
                <tr>
                  <td />
                  <td>Usia</td>
                  <td> : (usia)</td>
                </tr>
              </table>
            </div>
            <div className="laporan-ki4__header-title3--right">
              <table className="laporan-ki4__table-test">
                <tr>
                  <td />
                  <td>Kelas</td>
                  <td> : (kelas)</td>
                </tr>
                <tr />
              </table>
            </div>
          </div>
          <div className="manage-registration">
            <div className="fund-request__content">
              <table className="manage-rapbs-plafon__form-table table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Tanggal</th>
                    <th>KD</th>
                    <th>Indikator</th>
                    <th>Catatan</th>
                  </tr>
                </thead>
                <tbody>
                  {map(sub_tema, (o, idxsub) => (
                    <tr key={`plafon_journal_data__${idxsub}`}>
                      <td>{idxsub + 1}</td>
                    </tr>

                  ))}
                </tbody>
              </table>
            </div>
          </div>

        </form>
      </div>
    );
  }
}
CatatanAnekdotView.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
  handleGetCatatanAnekdot: PropTypes.func,
  handleSaveCatatanAnekdot: PropTypes.func,
  handleEditLembarObsHarian: PropTypes.func,
  getPeriodsOptions: PropTypes.func,
  getPrmAspectOptions: PropTypes.func,
  getKDMaterial: PropTypes.func,
  getPrmTema: PropTypes.func,
  getPrmSubTema: PropTypes.func,
  user: PropTypes.object,
};
CatatanAnekdotView.defaultProps = {
  history: null,
  location: null,
  handleGetCatatanAnekdot: noop,
  handleSaveCatatanAnekdot: noop,
  handleEditLembarObsHarian: noop,
  getPeriodsOptions: noop,
  getPrmAspectOptions: noop,
  getKDMaterial: noop,
  getPrmTema: noop,
  getPrmSubTema: noop,
  user: null,
};
